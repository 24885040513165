<template>
    <div>

        <!-- STEPPER -->
        <v-stepper v-model="currentStep" dark style="background-color:#323548;">
            <v-stepper-header class="wizard-header">
                <v-stepper-step :complete="currentStep > 1" step="1">
                    Tax Year
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="currentStep > 2" step="2">
                    Exchange
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="currentStep > 3" step="3">
                    Tax Method
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="currentStep > 4" step="4">
                    Upload File
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="5">
                    Verify
                </v-stepper-step>
            </v-stepper-header>

            <!-- STEPS -->
            <v-stepper-items>

                <!-- TAX YEAR -->
                <v-stepper-content step="1">

                    <v-card class="mb-12 step-card">

                        <div class="text-h6 mb-4 ml-5 pt-10">
                            <span class="text-capitalize text-monospace"> What year are you filing your cryptocurrency taxes for?</span>
                            <br />
                            <v-menu key="ddlTaxYears" rounded="b-xl" offset-y>

                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn color="blue"
                                           class="white--text mt-4"
                                           v-bind="attrs"
                                           v-on="on">
                                        Select Tax Year
                                    </v-btn>
                                </template>

                                <v-list class="pa-0">
                                    <v-list-item v-for="(item, index) in taxYears"
                                                 :key="index"
                                                 link
                                                 @click="changeYear(item)">
                                        <v-list-item-title v-text="item"></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                        </div>

                        <span v-if="!disableTaxYearContinue" class="ml-5 text-capitalize text-monospace span-selected-item">
                            Selected Tax Year:
                        </span>
                        <div v-if="!disableTaxYearContinue" class="ml-5 text-capitalize text-monospace span-selected-item span-selected-item-color">
                            {{selectedTaxYear}}
                        </div>

                        <b-button v-b-toggle.sidebar-taxYear class="btn-sm mr-2" style="position:absolute; top:10px; right:0px; background-color:var(--var-theme-color);">
                            <font-awesome-icon :icon="['fas', 'question-circle']" color="white" /><span class="white--text pl-2">Help</span>
                        </b-button>

                        <br />

                    </v-card>

                    <v-btn small color="primary" @click="currentStep = 2" :disabled="disableTaxYearContinue"> Continue </v-btn>

                    <v-btn small @click="cancelUpload();" class="float-right cancel-button">Cancel</v-btn>

                    <br />

                </v-stepper-content>

                <!-- EXCHANGE -->
                <v-stepper-content step="2">

                    <v-card class="mb-12 step-card">

                        <div class="text-h6 mb-4 ml-5 pt-10">
                            <span class="text-capitalize text-monospace">What exchange prepared your tax file?</span>
                            <br />
                            <v-menu key="ddlExchange" rounded="b-xl" offset-y>

                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn color="blue"
                                           class="white--text mt-4"
                                           v-bind="attrs"
                                           v-on="on">
                                        Select Exchange
                                    </v-btn>
                                </template>

                                <v-list class="pa-0">
                                    <v-list-item v-for="(item, index) in exchanges"
                                                 :key="index"
                                                 link
                                                 @click="changeExchange(item)">
                                        <v-list-item-title v-text="item"></v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                        </div>

                        <span v-if="!disableExchangeContinue && !IsManualExchange" class="ml-5 text-capitalize text-monospace span-selected-item">
                            Selected Exchange:
                        </span>
                        <div class="ml-5 text-capitalize text-monospace span-selected-item span-selected-item-color">
                            {{selectedExchange}}
                        </div>
                        <div v-if="IsManualExchange" class="ml-5 mr-2">
                            <v-text-field light
                                          v-model="selectedManualExchange"
                                          name="txtManualExchange"
                                          :rules="manualExchangeRules"
                                          placeholder="Enter Exchange Name"
                                          style="border-radius: 5px; height:30px; min-width:100px; max-width:250px;"
                                          dense>
                            </v-text-field>
                        </div>

                        <b-button v-b-toggle.sidebar-exchange class="btn-sm mr-2" style="position:absolute; top:10px; right:0px; background-color:var(--var-theme-color);">
                            <font-awesome-icon :icon="['fas', 'question-circle']" color="white" /><span class="white--text pl-2">Help</span>
                        </b-button>

                        <br />

                    </v-card>

                    <v-btn small color="warning" class="mr-2" @click="currentStep = 1"> Back </v-btn>

                    <v-btn small color="primary" @click="currentStep = 3" :disabled="disableExchangeContinue"> Continue </v-btn>

                    <v-btn small @click="cancelUpload();" class="ml-4 float-right cancel-button">Cancel</v-btn>

                    <br />
                </v-stepper-content>

                <!-- TAX METHOD -->
                <v-stepper-content step="3">

                    <v-card class="mb-12 step-card">

                        <div class="text-h6 mb-4 ml-5 pt-10">

                            <div v-if="disableTaxMethodStep">
                                <span style="color:maroon;" class="text-capitalize text-monospace">Files from exchanges such as {{selectedExchange}} have already been processed with a tax method</span>
                                <br />
                            </div>

                            <div v-if="!disableTaxMethodStep">

                                <span class="text-capitalize text-monospace">What accounting tax method do you want to use for this file?</span>
                                <br />
                                <v-menu key="ddlTaxMethod" rounded="b-xl" offset-y>

                                    <template v-slot:activator="{ attrs, on }">
                                        <v-btn color="blue"
                                               class="white--text mt-4"
                                               v-bind="attrs"
                                               v-on="on">
                                            Select Tax Method
                                        </v-btn>
                                    </template>

                                    <v-list class="pa-0">
                                        <v-list-item v-for="(item, index) in taxMethods"
                                                     :key="index"
                                                     link
                                                     @click="changeTaxMethod(item)">
                                            <v-list-item-title v-text="item"></v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>

                        <span v-if="!disableTaxMethodContinue" class="ml-5 text-capitalize text-monospace span-selected-item">
                            Selected Tax Method:
                        </span>
                        <div v-if="!disableTaxMethodContinue" class="ml-5 text-capitalize text-monospace span-selected-item span-selected-item-color">
                            {{selectedTaxMethod}}
                        </div>

                        <b-button v-b-toggle.sidebar-taxMethod class="btn-sm mr-2" style="position:absolute; top:10px; right:0px; background-color:var(--var-theme-color);">
                            <font-awesome-icon :icon="['fas', 'question-circle']" color="white" /><span class="white--text pl-2">Help</span>
                        </b-button>

                        <br />

                    </v-card>

                    <v-btn small color="warning" class="mr-2" @click="currentStep = 2"> Back </v-btn>

                    <v-btn small color="primary" @click="currentStep = 4" :disabled="disableTaxMethodContinue"> Continue </v-btn>

                    <v-btn small @click="cancelUpload();" class="ml-4 float-right cancel-button">Cancel</v-btn>

                    <br />
                </v-stepper-content>

                <!-- FILE UPLOAD -->
                <v-stepper-content step="4">

                    <v-card class="mb-12 step-card">

                        <div v-if="IsManualExchange" class="text-h6 mb-4 ml-5 pt-10">
                            <span style="color:maroon;" class="text-capitalize text-monospace">You can manually enter your transactions on the next screen using Stick-Shift. Please press continue to proceed.</span>
                            <br />
                        </div>

                        <div v-if="!IsManualExchange">
                            <div class="text-h6 mb-4 ml-5 pt-10" style="display:block;">
                                <span class="text-capitalize text-monospace">Upload your {{selectedExchange}} tax file:</span>
                            </div>

                            <div class="mb-4 ml-4 pt-10">
                                <TaxFileUploader ref="stepfileUploader"
                                                 :TaxYear="selectedTaxYear"
                                                 :Exchange="selectedExchange"
                                                 :TaxMethod="selectedTaxMethod"
                                                 @uploadOpen="uploaderOpen"
                                                 @uploadSuccess="uploaderSuccess"
                                                 @uploadFail="uploaderFail" />
                            </div>
                        </div>

                        <b-button v-b-toggle.sidebar-uploadFile class="btn-sm mr-2" style="position:absolute; top:10px; right:0px; background-color:var(--var-theme-color);">
                            <font-awesome-icon :icon="['fas', 'question-circle']" color="white" /><span class="white--text pl-2">Help</span>
                        </b-button>

                        <br />

                    </v-card>

                    <v-btn small color="warning" class="mr-2" @click="currentStep = 3"> Back </v-btn>

                    <v-btn small color="primary" @click="goToVerification" :disabled="disableContinueToVerify"> Continue </v-btn>

                    <v-btn small @click="cancelUpload();" class="ml-4 float-right cancel-button">Cancel</v-btn>

                    <br />
                </v-stepper-content>

                <!-- TAX SUMMARY / VERIFICATION -->
                <v-stepper-content step="5">

                    <v-card class="mb-12 step-card">

                        <div class="mb-4 ml-5 mr-5 pt-10">
                            <span class="text-capitalize text-monospace" style="font-weight:bold; font-size:large;">Verify your tax data</span>

                            <b-table ref="tblTaxSummary"
                                     id="tblTaxSummary"
                                     :fields="taxSummaryFields"
                                     :items="taxSummary"
                                     sticky-header
                                     small
                                     table-variant="primary"
                                     class="vertical-align: middle"
                                     stacked="md"
                                     :bordered="true">
                            </b-table>
                            <br />

                            <v-btn v-if="enableStickShift" color="#BD2810" style="color:white;" @click="showStickShiftDialog();" class="float-right" small>
                                Stick-Shift
                                <font-awesome-icon :icon="['fas', 'cogs']" color="white" class="ml-2" />
                            </v-btn>

                        </div>

                        <b-button v-b-toggle.sidebar-verify class="btn-sm mr-2" style="position:absolute; top:10px; right:0px; background-color:var(--var-theme-color);">
                            <font-awesome-icon :icon="['fas', 'question-circle']" color="white" /><span class="white--text pl-2">Help</span>
                        </b-button>

                        <br />

                    </v-card>

                    <v-btn small color="warning" class="mr-2" @click="currentStep = 4"> Back </v-btn>

                    <v-btn small color="primary" @click="verifyTaxFile();" :disabled="disableVerify"> Verify </v-btn>

                    <v-btn small @click="cancelUpload();" class="ml-4 float-right cancel-button">Cancel</v-btn>


                    <div class="mt-4" style="font-size:small;" v-if="disableVerify">You must have at least one taxable transaction to verify.</div>
                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>

        <!-- SIDE BARS -->
        <div>
            <b-sidebar id="sidebar-taxYear" title="Tax Year" right shadow bg-variant="dark" text-variant="light">
                <div class="px-3 py-2">

                    <p>
                        <font-awesome-icon :icon="['fas', 'clock']" class="fa-3x" color="var(--var-theme-color)" />
                        Tax year is just the year you sold your cryptocurrency.  For this step, unless you are amending a previous tax return, you should always choose the prior year.
                    </p>
                    <p>
                        <em>(Example: Rich is doing his taxes in March of 2022, he would select year 2021 in CryptoTaxSpace)</em>
                    </p>
                    <p>
                        Important Note: The tax file you upload must include any previous years crypto transactions so that CryptoTaxSpace can calculate your accurate gains/losses.  CryptoTaxSpace processes based on the file provided and is not liable for any missing data.
                    </p>
                    <br />
                    <br />
                    <b-button v-b-toggle.sidebar-taxYear class="mt-5" style="background-color:var(--var-theme-color);"><span class="white--text">Close</span></b-button>
                </div>
            </b-sidebar>

            <b-sidebar id="sidebar-exchange" title="Exchange" right shadow bg-variant="dark" text-variant="light">
                <div class="px-3 py-2">

                    <p>
                        <font-awesome-icon :icon="['fas', 'handshake']" class="fa-3x" color="var(--var-theme-color)" />
                        Each cryptocurrency exchange you use should provide you a file with your transactions over the given year.  Some provide it in 1 file and others may provide multiple files.  Select the Exchange that you are importing.  We proudly aggregate Coinbase and Robinhood at this time.
                    </p>
                    <p>
                        Important Note:  CryptoTaxSpace validates all your sells has a previous purchase or transfer to cover your sell.  If CryptoTaxSpace finds a sell that cannot be allocated to a previous buy or transfer it will prompt that you have “Uncovered Transactions”.  Not to worry, CryptoTaxSpace allows a Stick-Shift adjustment to fix.
                    </p>
                    <br />
                    <b-button v-b-toggle.sidebar-exchange class="mt-5" style="background-color:var(--var-theme-color);"><span class="white--text">Close</span></b-button>
                </div>
            </b-sidebar>

            <b-sidebar id="sidebar-taxMethod" title="Tax Method" right shadow bg-variant="dark" text-variant="light">
                <div class="px-3 py-2">

                    <p>
                        <font-awesome-icon :icon="['fas', 'calculator']" class="fa-3x" color="var(--var-theme-color)" />
                        The IRS allows various ways to calculate your gains/losses.  HIFI, FIFO, LIFO is what CryptoTaxSpace supports.  You basically will pay taxes on gains or realize your losses earlier or later depending on the accounting method you use.  HIFO means highest priced crypto price you paid will be used to calculate the gain/loss.  LIFO means the last crypto you bought will be used to calculate gains/losses.  While FIFO means the first crypto you bought will be used to calculate your gains/losses.  No worries as CryptoTaxSpace will let you pick the best one for you at the time.  In some instances your tax burden will be different between the methods.  Be sure to compare.
                    </p>
                    <br />
                    <b-button v-b-toggle.sidebar-taxMethod class="mt-5" style="background-color:var(--var-theme-color);"><span class="white--text">Close</span></b-button>
                </div>
            </b-sidebar>

            <b-sidebar id="sidebar-uploadFile" title="Upload File" right shadow bg-variant="dark" text-variant="light">
                <div class="px-3 py-2">

                    <p>
                        <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" class="fa-3x" color="var(--var-theme-color)" />
                        Select the previously downloaded exchange tax file.  This should be downloaded from the crypto exchange prior to starting on CryptoTaxSpace.  Help for Coinbase.  Help for Robinhood.
                    </p>
                    <br />
                    <b-button v-b-toggle.sidebar-uploadFile class="mt-5" style="background-color:var(--var-theme-color);"><span class="white--text">Close</span></b-button>
                </div>
            </b-sidebar>

            <b-sidebar id="sidebar-verify" title="Verify" right shadow bg-variant="dark" text-variant="light">
                <div class="px-3 py-2">

                    <p>
                        <font-awesome-icon :icon="['fas', 'check-circle']" class="fa-3x" color="var(--var-theme-color)" />
                        This is where we show you if all your transactions are covered (accounted for) or if you have “Uncovered Transactions”.
                        Click “Stick-Shift” to fix your uncovered transactions if you have any.
                        If you have 0 uncovered transactions click “Verify”.
                        You will proceed to the next screen to see all your transactions.
                        Use the “Charts” tab to see your allocation breakdown of coins.
                        Download your CSV file and you can now import to Turbotax.
                    </p>
                    <br />
                    <em>
                        Note: You also have the option to manually approve or reject any uncovered transactions if you verify this upload without correcting these transactions using Stick-Shift.
                        Keep in mind that any transactions that are approved outside of Stick-Shift will not be processed using your selected tax method.
                    </em>
                    <br />
                    <b-button v-b-toggle.sidebar-verify class="mt-5" style="background-color:var(--var-theme-color);"><span class="white--text">Close</span></b-button>
                </div>
            </b-sidebar>

        </div>

        <!-- UNCOVERED ACCEPTANCE DIALOG-->
        <v-dialog v-model="showAcceptanceDialog" width="500" dark>

            <v-card>
                <v-card-title class="text-h5 red--text">
                    Warning: Uncovered Transactions
                </v-card-title>

                <v-card-text class="white--text">
                    Are you sure you wish to continue? You have uncovered transactions in the file you uploaded. This could lead to inaccurate capital gains if not corrected. If you choose to continue, you can reject these transactions completely from your tax export or supply a spot price for each uncovered transaction. It is highly recommended you correct these transactions using Stick-Shift as it will guarantee that all transactions have been processed using the appropriate tax method.
                </v-card-text>

                <v-divider></v-divider>

                <v-btn color="red"
                       class="mb-4"
                       text
                       @click="acceptUncoveredTransactions">
                    Proceed without correcting
                </v-btn>

                <div class="float-right">
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn color="primary"
                               text
                               @click="showStickShiftDialog">
                            Fix With Stick-Shift
                        </v-btn>
                    </div>
                </div>

                <br />

            </v-card>
        </v-dialog>

        <StickShift ref="stickShiftModal"
                    :TaxYear="selectedTaxYear"
                    :Exchange="selectedExchange"
                    :TaxMethod="selectedTaxMethod"
                    :ManualExchange="selectedManualExchange"
                    :UploadId="selectedUploadId"
                    @processSuccess="stickShiftSuccess" />

        </div>
</template>

<script>

    const TaxFileUploader = () => import('@/components/tax-file-uploader');
    const StickShift = () => import('@/components/stick-shift');
    import { notificationHooks } from '../shared/vue-mixin';

    export default {
        name: 'TaxFileWizard',

        /* COMPONENT DATA  */
        data() {
            return {
                currentStep: 1,
                taxYears: ['All Tax Years', '2021', '2020', '2019', '2018', '2017', '2016'],
                exchanges: ['Coinbase', 'Robinhood', 'Enter Transactions Manually'],
                taxMethods: ['HIFO', 'FIFO', 'LIFO'],
                stickShiftRecords: [],
                uncoveredTransactions: [],
                showAcceptanceDialog: false,
                selectedTaxYear: '',
                selectedExchange: '',
                selectedTaxMethod: '',
                selectedUploadId: null,
                selectedManualExchange: '',
                taxFile: null,
                rawTaxFile: null,
                manualExchangeRules: [
                    v => !!v || 'Exchange name is required',
                    v => v.length >= 3 || 'Min 3 characters',
                    v => v.length <= 25 || 'Max 25 characters'
                ],
                taxSummaryFields: [
                    { key: 'year', label: "Tax Year", sortable: false, class: 'centeredCell' },
                    { key: 'exchange', label: "Exchange", sortable: false, class: 'centeredCell' },
                    { key: 'method', label: "Accounting Method", sortable: false, class: 'centeredCell' },
                    { key: 'gain', label: "Total Gain/Loss", sortable: false, class: 'centeredCell' },
                    { key: 'uncovered', label: "Uncovered Transactions", sortable: false, class: 'centeredCell' },
                ],
            };
        },

        /* MIXINS */
        mixins: [notificationHooks],

        /* CHILD COMPONENTS  */
        components: {
            TaxFileUploader,
            StickShift
        },

        /* COMPONENT METHODS  */
        methods: {

            changeYear(year) {
                this.selectedTaxYear = year;
                this.selectedExchange = '';
                this.selectedManualExchange = '';
                this.selectedTaxMethod = '';
                this.taxFile = null;
                this.stickShiftRecords = [];
                this.uncoveredTransactions = [];
                if (this.$refs.stepfileUploader) {
                    //Clear if the file uploader component has been registered.
                    this.$refs.stepfileUploader.ClearUpload();
                }
            },

            changeExchange(exchange) {
                this.selectedExchange = exchange;
                this.selectedManualExchange = '';
                this.selectedTaxMethod = '';
                this.taxFile = null;
                this.stickShiftRecords = [];
                this.uncoveredTransactions = [];
                if (this.$refs.stepfileUploader) {
                    //Clear if the file uploader component has been registered.
                    this.$refs.stepfileUploader.ClearUpload();
                }
                if (this.selectedExchange === "Robinhood") {
                    this.selectedTaxMethod = "Not Applicable";
                }
            },

            changeTaxMethod(method) {
                this.selectedTaxMethod = method;
                this.taxFile = null;
                this.stickShiftRecords = [];
                this.uncoveredTransactions = [];
                if (this.$refs.stepfileUploader) {
                    //Clear if the file uploader component has been registered.
                    this.$refs.stepfileUploader.ClearUpload();
                }
            },

            uploaderOpen() {
                this.taxFile = null;
                this.stickShiftRecords = [];
                this.uncoveredTransactions = [];
                this.$refs.stepfileUploader.ClearUpload();
            },

            uploaderSuccess(response, uploadedContents) {
                this.taxFile = response;
                this.stickShiftRecords = [];
                this.uncoveredTransactions = response.uncoveredTransactions;
                this.selectedUploadId = response.uploadId;
                this.rawTaxFile = uploadedContents; //Save these for use with Stick-Shift
                this.showSuccessMessage("CryptoTaxSpace", "File upload was successfully processed.");
            },

            stickShiftSuccess(response, stickShiftRecords) {
                this.taxFile = response;
                this.stickShiftRecords = stickShiftRecords;
                this.uncoveredTransactions = response.uncoveredTransactions;
                this.selectedUploadId = response.uploadId;
                this.showSuccessMessage("CryptoTaxSpace", "Your tax records have been successfully processed.");
            },

            uploaderFail(error) {
                this.showErrorMessage("CryptoTaxSpace", error);
            },

            cancelUpload() {
                this.selectedTaxYear = '';
                this.selectedExchange = '';
                this.selectedTaxMethod = '';
                this.selectedManualExchange = '';
                this.taxFile = null;
                this.rawTaxFile = null;
                this.currentStep = 1;
                if (this.$refs.stepfileUploader) {
                    //Clear if the file uploader component has been registered.
                    this.$refs.stepfileUploader.ClearUpload();
                }
                this.$emit('cancelUpload');
                //Remove this control from the parent
            },

            goToVerification() {

                //Manually create api response if upload wasn't necessary
                if (this.selectedExchange === "Enter Transactions Manually") {
                    this.taxFile = {
                        uploadId: Date.now(),
                        records: [],
                        logs: [],
                        taxYear: this.selectedTaxYear,
                        taxMethod: this.selectedTaxMethod,
                        exchange: "Manual",
                        uncoveredTransactions: [],
                        manualCorrections: []
                    }
                }
                this.currentStep = 5;
            },

            verifyTaxFile() {
                if (this.uncoveredTransactions.length === 0) {
                    this.$emit('addUpload', this.taxFile, this.stickShiftRecords);
                }
                else {
                    this.showAcceptanceDialog = true;
                }
            },

            acceptUncoveredTransactions() {
                this.showAcceptanceDialog = false;
                this.$emit("addUpload", this.taxFile, this.stickShiftRecords);
            },

            showStickShiftDialog() {
                this.showAcceptanceDialog = false;
                this.$refs.stickShiftModal.ShowModal(this.rawTaxFile, this.stickShiftRecords, this.uncoveredTransactions);
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {

            disableTaxYearContinue() {
                return this.selectedTaxYear === "" || this.selectedTaxYear === null;
            },

            disableExchangeContinue() {
                return this.selectedExchange === "" || this.selectedExchange === null || (this.IsManualExchange && !this.HasManualExchangeText);
            },

            disableTaxMethodContinue() {
                return this.selectedTaxMethod === "" || this.selectedTaxMethod === null;
            },

            disableTaxMethodStep() {
                return this.selectedExchange === "Robinhood";
            },

            IsManualExchange() {
                return this.selectedExchange === "Enter Transactions Manually";
            },

            HasManualExchangeText() {
                return this.selectedManualExchange.length >= 3 && this.selectedManualExchange.length <= 25;
            },

            exchangeText() {
                if (this.IsManualExchange) {
                    return this.selectedManualExchange + " (Manual)";
                }
                return this.selectedExchange;
            },

            disableContinueToVerify() {
                return (this.taxFile === null || this.taxFile === undefined) && !this.IsManualExchange;
            },

            disableVerify() {
                return (this.taxFile === null || this.taxFile === undefined || (this.taxFile.records.length === 0 && this.taxFile.uncoveredTransactions.length === 0));
            },

            getPreviousStep() {
                if (this.selectedExchange === "Robinhood") {
                    return this.currentStep - 2;
                }
                return this.currentStep - 1;
            },

            taxSummary() {

                var allTaxSummaries = [];

                if (this.taxFile) {

                    var gainLoss = 0;
                    if (this.taxFile !== null) {
                        this.taxFile.records.forEach(
                            record => {
                                gainLoss += record.gain
                            });
                    }

                    var errorCount = this.taxFile.logs.filter(x => x.transactionType === "Error").length;
                    var formattedGainLoss = gainLoss.toFixed(2);
                    var summary =
                    {
                        "uploadId": this.taxFile.uploadId,
                        "year": this.selectedTaxYear,
                        "exchange": this.exchangeText,
                        "method": this.selectedTaxMethod,
                        "gain": "$" + formattedGainLoss,
                        "uncovered": errorCount
                    };

                    allTaxSummaries.push(summary);
                }

                return allTaxSummaries;
            },

            enableStickShift() {
                return this.selectedExchange !== "Robinhood";
            },
        }

    };
</script>



<style scoped>

    /deep/ .centeredCell {
        vertical-align: middle !important;
    }

    /deep/ .b-sidebar-body {
        /* Set help side bar color  here */
        background-color: #424752;
        color: white;
    }

    /deep/ v-dialog--active {
        background-color: white !important;
    }

    /deep/ .v-divider {
        border-color: white !important;
    }

    .wizard-header {
        background-color:black;
        color:white;
    }

    .step-card {
        background-color:white; 
        color:black; 
        min-height:220px;
    }

    .span-selected-item {
        font-size: large !important;
        font-weight: bold;
    }

    .span-selected-item-color {
        color: #034BEF;
    }

    .cancel-button
    {
        background-color:white !important;
        color:black;
    }


</style>